var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"row justify-content-end"},[_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-info",on:{"click":function($event){return _vm.refresh()}}},[_c('i',{staticClass:"bi bi-arrow-clockwise"}),_vm._v(" Actualiser ")])]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$router.push({ name: 'items-create' })}}},[_c('i',{staticClass:"bi bi-plus-square"}),_vm._v(" Créer un nouveau produit ")])]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.printAll()}}},[_c('i',{staticClass:"bi bi-printer"}),_vm._v(" Imprimer ")])])]),_c('br'),_c('table',{staticClass:"table table-striped table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index++},[_c('td',[_vm._v(" "+_vm._s(index)+" ")]),_c('td',{staticClass:"p-0 m-0"},[_vm._v(" "+_vm._s(item.reference)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.fullName)+" ")]),_c('td',[(item.section)?_c('span',[_vm._v(" "+_vm._s(item.section.fullName)+" ")]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(item.measuring)+" ")]),_c('td',[_vm._v(" "+_vm._s(parseFloat(item.priceTTC).toFixed(2))+" ")]),_c('td',[_vm._v(_vm._s(item.tva)+" %")]),_c('td',{staticClass:"d-flex"},[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.$router.push({
                name: 'items-edit',
                params: {
                  reference: item.reference,
                },
              })}}},[_c('i',{staticClass:"bi bi-pencil-square"})]),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.remove(item)}}},[_c('i',{staticClass:"bi bi-trash text-danger"})])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}}),_c('th',{attrs:{"scope":"col"}},[_vm._v("Référence Nº")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Désignation")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Catégorie")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Unité")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Prix TTC")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("TVA")]),_c('th',{attrs:{"scope":"col"}})])])
}]

export { render, staticRenderFns }